import { AppTableTotal, ClientEmployeeListHeader } from "commonPartials";
import AppContentHeader from "components/AppContentHeader";
import { AppRoundedTextIconButton } from "components/Buttons";
import { ImageAssets } from "globals/images";
import { useRouting } from "hooks/general/routing";
import { useCheckPermission } from "hooks/permissionCheck";
import { defaultTo, isNil } from "lodash-es";
import { Kindergarten_Client_BasicData, PermissionAccessTypes } from "models";
import { UserType } from "models/general";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useKindergartenClientContext } from "./KindergartenClientsContextProvider";
import styles from "./KindergartenClientsList.module.scss";
import { KindergartenClientsTable } from "./partials";
export const KindergartenClientsList = () => {
    const { linkProvider } = useRouting();
    const navigation = useNavigate();
    const { t } = useTranslation();

    const { checkPermission } = useCheckPermission();
    const hasCreatePermission = checkPermission(Kindergarten_Client_BasicData, [
        PermissionAccessTypes.CREATE,
    ]);

    const {
        clients,
        filters,
        onFilterChange,
        onSortChange,
        headerFilterOptions,
        sort,
        setNavigateUsingCompleteResponse,
        isLoading,
        completeResponse,
        onChangeCompleteResponse,
        onChangeClientList,
    } = useKindergartenClientContext();

    return (
        <div className={styles.root}>
            <AppContentHeader
                title={t("kindergartenClient.title")}
                icon={ImageAssets.common.clients}
            >
                <div className="d-flex align-items-center">
                    <ClientEmployeeListHeader
                        selectedIds={clients
                            ?.filter((x) => x.isChecked)
                            .map((x) => {
                                return defaultTo(x.Id, 0);
                            })
                            .filter((x) => x != 0)}
                        userType={UserType.Client}
                        hasCreatePermission={hasCreatePermission}
                    />
                    {hasCreatePermission && (
                        <AppRoundedTextIconButton
                            className="ml-3"
                            onClick={() =>
                                navigation(
                                    linkProvider.kindergarten
                                        .clients()
                                        .screens.client.create()
                                )
                            }
                        />
                    )}
                </div>
            </AppContentHeader>
            <div className={styles.content}>
                <KindergartenClientsTable
                    onEditClick={(encodedId: string) => {
                        setNavigateUsingCompleteResponse(false);
                        navigation(
                            linkProvider.kindergarten
                                .clients()
                                .screens.client.withId(encodedId)
                                .edit()
                        );
                    }}
                    headerFilterOptions={headerFilterOptions}
                    sort={sort}
                    filterValues={filters}
                    onFilterChange={onFilterChange}
                    loading={defaultTo(isNil(clients) || isLoading, true)}
                    value={clients}
                    onSortChange={onSortChange}
                    completeResponse={completeResponse}
                    onChangeCompleteResponse={onChangeCompleteResponse}
                    onChange={onChangeClientList}
                />
                <AppTableTotal
                    text={t("kindergartenClient.totalClients")}
                    count={clients ? clients.length : 0}
                />
            </div>
        </div>
    );
};

export default KindergartenClientsList;
