import { ISO8601_DATE_FORMAT } from "globals/constants";
import { Optional } from "models/general";
import moment from "moment";
import {
    EmployeeContract,
    EmployeeContractBase,
    EmployeeContractNote,
    EmployeeContractNoteBase,
} from "./model";

export interface EmployeeContractNoteResponse extends EmployeeContractNoteBase {
    CreatedAt: number;
}
export interface EmployeeContractResponse extends EmployeeContractBase {
    Notes?: EmployeeContractNoteResponse[];
    ContractStart: Optional<string>;
    ContractEnd?: Optional<string>;
    EndOfTrialPeriod?: Optional<string>;
    ExpirationDate?: Optional<string>;
}

export const getParsedEmployeeContractNote = (
    val: EmployeeContractNoteResponse
): EmployeeContractNote => {
    return {
        ...val,
        CreatedAt: moment.unix(val.CreatedAt),
    };
};
export const getParsedEmployeeContract = (
    val: EmployeeContractResponse
): EmployeeContract => {
    return {
        ...val,
        Notes: val.Notes
            ? val.Notes.map((x) => getParsedEmployeeContractNote(x))
            : [],
        ContractStart: moment(val.ContractStart, ISO8601_DATE_FORMAT),
        ContractEnd: val.ContractEnd
            ? moment(val.ContractEnd, ISO8601_DATE_FORMAT)
            : null,
        ExpirationDate: val.ExpirationDate
            ? moment(val.ExpirationDate, ISO8601_DATE_FORMAT)
            : null,
        EndOfTrialPeriod: val.EndOfTrialPeriod
            ? moment(val.EndOfTrialPeriod, ISO8601_DATE_FORMAT)
            : null,
    };
};
