import { AppButton, AppButtonProps } from "components/Buttons";
import { defaultTo, isNil } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./AppDialogFooter.module.scss";
export interface AppDialogFooterProps extends AppButtonProps {
    onDialogClose?: () => void;
    onClickSaveButton?: (e: any) => void;
    onClickDeleteButton?: (e: any) => void;
    disableSaveButton?: boolean;
    showCancel?: boolean;
    disableCancelButton?: boolean;
    disableDeleteButton?: boolean;
    saveButtonText?: string;
    deleteButtonText?: string;
    showDeleteBtn?: boolean;
    showSave?: boolean;
}
export const AppDialogFooter: React.FC<AppDialogFooterProps> = ({
    onDialogClose,
    onClickSaveButton,
    onClickDeleteButton,
    showCancel = true,
    showSave = true,
    disableSaveButton = false,
    disableCancelButton = false,
    disableDeleteButton = false,
    saveButtonText,
    deleteButtonText,
    showDeleteBtn = false,
    ...rest
}) => {
    const { t } = useTranslation();
    return (
        <div className={styles.footer}>
            {showDeleteBtn && (
                <AppButton
                    variant="danger"
                    className={styles.deleteBtn}
                    disabled={isNil(onClickDeleteButton) || disableDeleteButton}
                    onClick={onClickDeleteButton}
                >
                    <span>
                        {defaultTo(deleteButtonText, t("common.deleteText"))}
                    </span>
                </AppButton>
            )}
            <div style={{ marginLeft: "auto" }}>
                {showCancel && (
                    <AppButton
                        variant="light"
                        className={styles.cancel}
                        disabled={isNil(onDialogClose) || disableCancelButton}
                        onClick={onDialogClose}
                    >
                        {t("common.cancel")}
                    </AppButton>
                )}
                {showSave && (
                    <AppButton
                        onClick={(e) => {
                            if (onClickSaveButton) {
                                onClickSaveButton(e);
                            }
                        }}
                        disabled={disableSaveButton}
                        className={styles.saveBtn}
                        {...rest}
                    >
                        <span>
                            {defaultTo(saveButtonText, t("common.save"))}
                        </span>
                    </AppButton>
                )}
            </div>
        </div>
    );
};

export default AppDialogFooter;
