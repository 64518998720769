import { AppTableSortColumnIcons } from "components/Table";
import { getFixedCssWidths } from "globals/helpers/generalHelper";
import { t } from "i18next";
import { SortOrder, TableSort } from "models/general";
import { ClientRegistrationSortColumn } from "models/kindergartenClientRegistration";
import React from "react";

export interface KindergartenClientRegistrationHeaderRowProps {
    value: TableSort<ClientRegistrationSortColumn>;
    onChange: (key: ClientRegistrationSortColumn, order: SortOrder) => void;
}

export const KindergartenClientRegistrationHeaderRow: React.FC<
    KindergartenClientRegistrationHeaderRowProps
> = ({ value, onChange }) => {
    return (
        <tr>
            <AppTableSortColumnIcons
                style={getFixedCssWidths(300)}
                text={t("kindergartenClient.fullName")}
                sortColumn={ClientRegistrationSortColumn.Name}
                sortOrder={
                    value.SortColumn === ClientRegistrationSortColumn.Name
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(170)}
                text={t("common.gender.name")}
                sortColumn={ClientRegistrationSortColumn.Gender}
                sortOrder={
                    value.SortColumn === ClientRegistrationSortColumn.Gender
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(130)}
                text={t("kindergartenClient.registration.year")}
                sortColumn={ClientRegistrationSortColumn.Year}
                sortOrder={
                    value.SortColumn === ClientRegistrationSortColumn.Year
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.basicInfo.birthday.name")}
                sortColumn={ClientRegistrationSortColumn.Birthday}
                sortOrder={
                    value.SortColumn === ClientRegistrationSortColumn.Birthday
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.registration.careStart")}
                sortColumn={ClientRegistrationSortColumn.CareStart}
                sortOrder={
                    value.SortColumn === ClientRegistrationSortColumn.CareStart
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            {/* <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.basicInfo.ageGroup")}
                sortColumn={ClientRegistrationSortColumn.AgeGroup}
                sortOrder={
                    value.SortColumn === ClientRegistrationSortColumn.AgeGroup
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            /> */}
            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.registration.scopeOfCare.name")}
                sortColumn={ClientRegistrationSortColumn.CareScope}
                sortOrder={
                    value.SortColumn === ClientRegistrationSortColumn.CareScope
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(150)}
                text={t("kindergartenClient.status")}
                sortColumn={ClientRegistrationSortColumn.Status}
                sortOrder={
                    value.SortColumn === ClientRegistrationSortColumn.Status
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(170)}
                text={t("kindergartenClient.registration.nationality.name")}
                sortColumn={ClientRegistrationSortColumn.Nationality}
                sortOrder={
                    value.SortColumn ===
                    ClientRegistrationSortColumn.Nationality
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            {/* <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.registration.progressStatus.name")}
                sortColumn={ClientRegistrationSortColumn.ProgressStatus}
                sortOrder={
                    value.SortColumn ===
                    ClientRegistrationSortColumn.ProgressStatus
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            /> */}
            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.registration.isSibling")}
                sortColumn={ClientRegistrationSortColumn.IsSibling}
                sortOrder={
                    value.SortColumn === ClientRegistrationSortColumn.IsSibling
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.registration.tableArrangedPlace")}
                sortColumn={ClientRegistrationSortColumn.ArrangedPlace}
                sortOrder={
                    value.SortColumn ===
                    ClientRegistrationSortColumn.ArrangedPlace
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(150)}
                text={t("kindergartenClient.registration.source")}
                sortColumn={ClientRegistrationSortColumn.Source}
                sortOrder={
                    value.SortColumn === ClientRegistrationSortColumn.Source
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            <AppTableSortColumnIcons
                style={getFixedCssWidths(200)}
                text={t("kindergartenClient.registration.createdAt")}
                sortColumn={ClientRegistrationSortColumn.CreatedAt}
                sortOrder={
                    value.SortColumn === ClientRegistrationSortColumn.CreatedAt
                        ? value.SortOrder
                        : undefined
                }
                onClick={onChange}
            />
            <th style={getFixedCssWidths(70)}></th>
        </tr>
    );
};

export default KindergartenClientRegistrationHeaderRow;
