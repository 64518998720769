import AppLoader from "components/Loaders/AppLoader";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useShowErrorPage } from "hooks/general/appHelpers";
import { useRouting } from "hooks/general/routing";
import { isNil } from "lodash-es";
import { getKindergartenClientRegistrationFromResponse } from "models/kindergartenClientRegistration/helper";
import { KindergartenClientRegistration } from "models/kindergartenClientRegistration/model";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useOutletContext, useParams } from "react-router";
import {
    ClientRegistrationContactInfoEdit,
    ClientRegistrationEditAdditionalInfo,
} from "screens/kindergarten/clientRegistration/createEdit";
import ClientRegistrationCreateEditBasicData from "screens/kindergarten/clientRegistration/createEdit/ClientRegistrationCreateEditBasicData";
import { KindergartenClientRegistrationOutletContext } from "screens/kindergarten/clientRegistration/KindergartenClientRegistrationMain";
import {
    getKindergartenClientRegistrationServiceKey,
    KindergartenClientRegistrationService,
} from "services/kindergarten/KindergartenClientRegistrationService";

export const KindergartenClientRegistrationEdit: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { setHeaderTitle, permMap } =
        useOutletContext<KindergartenClientRegistrationOutletContext>();
    const showErrorPage = useShowErrorPage();
    const queryClient = useQueryClient();
    const [state, setState] = useState<KindergartenClientRegistration>();
    const { sessionBusiness } = useSessionBusiness();
    const registrationService = new KindergartenClientRegistrationService(
        linkProvider.kindergarten.clients().api.clientRegistration
    );

    const { isFetching, data, refetch } = useQuery(
        getKindergartenClientRegistrationServiceKey("get", {
            id: id,
        }),
        async () => {
            if (id) {
                return await registrationService.get(id);
            }
        },
        {
            cacheTime: 0,
            select: (res) => {
                if (res?.Data) {
                    return getKindergartenClientRegistrationFromResponse(
                        res.Data
                    );
                } else if (!isNil(id) && res?.Errors) {
                    showErrorPage(404);
                }
            },
        }
    );
    useEffect(() => {
        if (!isFetching && data) {
            setHeaderTitle(data.Name as string);
            setState(data);
        }
    }, [isFetching]);

    return isNil(state) || isFetching ? (
        <AppLoader />
    ) : (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div>
                <ClientRegistrationCreateEditBasicData
                    isEdit={true}
                    isLoading={state === undefined || isFetching}
                    value={state}
                    permMap={permMap}
                    onSuccess={() => {
                        queryClient.invalidateQueries(
                            getKindergartenClientRegistrationServiceKey("list")
                        );
                        queryClient.invalidateQueries(
                            getKindergartenClientRegistrationServiceKey("get", {
                                id: state.Id,
                            })
                        );
                    }}
                    onChange={(val) => {
                        setState(val);
                    }}
                    deadLine={sessionBusiness?.BusinessSetting.AgeGroupDeadline}
                />
            </div>
            {state &&
                state.Parents &&
                state.Parents.map((parent, index) => (
                    <ClientRegistrationContactInfoEdit
                        indexId={index}
                        key={index}
                        type={parent.Type}
                        completeData={state}
                        contactData={parent}
                        onCustodianChange={(val) => setState(val)}
                        title={t(
                            `kindergartenClient.registration.parentType.${parent.Type}`
                        )}
                    />
                ))}

            {state && (
                <ClientRegistrationEditAdditionalInfo
                    value={state}
                    onChange={(val) => setState(val)}
                />
            )}
        </div>
    );
};
export default KindergartenClientRegistrationEdit;
