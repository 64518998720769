import { AppButton, AppDeleteButton, AppIconButton } from "components/Buttons";
import { PermissionAccessMap } from "models";
import {
    EmployeeContractNote,
    EmployeeContractNoteRequest,
    getParsedEmployeeContractNote,
} from "models/employeeContract";
import {
    getInitializedValidityState,
    ValidityStateManager,
} from "models/general";
import { useTranslation } from "react-i18next";
import styles from "./EmployeeContractNotes.module.scss";
import {
    showSweetAlertToast,
    showUnexpectedErrorToast,
} from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import { useMutation, useQueryClient } from "react-query";
import BusinessEmployeeContractService, {
    getBusinessEmployeeContractServiceKey,
} from "services/business/BusinessEmployeeContractService";
import { useMemo, useState } from "react";
import { useSessionUser } from "hooks/general/appContextHelpers";
import { validateEmployeeContractNote } from "models/employeeContract/validate";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { AppInputField } from "components/FormFields";
import { defaultTo } from "lodash-es";

export interface EmployeeContractNotesProps {
    contractId: number;
    value: EmployeeContractNote[];
    showTitle?: boolean;
    permMap: PermissionAccessMap;
    onChange: (data: EmployeeContractNote[]) => void;
}

export const EmployeeContractNotes: React.FC<EmployeeContractNotesProps> = ({
    contractId,
    value,
    permMap,
    showTitle = false,
    onChange,
}) => {
    const { t } = useTranslation();
    const { linkProvider } = useRouting();
    const { user } = useSessionUser();
    const { getDateTimeDisplayFormatForLocale } = useLocaleHelpers();
    const contractService = new BusinessEmployeeContractService(
        linkProvider.business.employees().api.withId().employeeContract
    );
    const queryClient = useQueryClient();

    const [state, setState] = useState<EmployeeContractNoteRequest>({
        ContractId: contractId,
        UserId: user?.Id,
        Note: "",
    });
    const { mutate: deleteNote, isLoading: deleting } = useMutation(
        async (id?: number) => await contractService.deleteNote(id ? id : 0),
        {
            onSuccess: (res) => {
                showSweetAlertToast(
                    t("common.success"),
                    `${t("employee.contract.contractNote")} ${t(
                        "common.actions.deletedSuccessfully"
                    )}`,
                    "success"
                );
                onChange(value.filter((x) => x.Id != res.Data));
                setState({ ...state, Note: "", Id: undefined });
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );

    const {
        isLoading: createUpdateLoading,
        mutate: createUpdate,
        data: updateResponse,
    } = useMutation(
        async () => {
            if (state) {
                return await contractService.addUpdateNote(state);
            }
        },
        {
            onSuccess: (res) => {
                if (res?.Data) {
                    const isEdit = defaultTo(state.Id, 0) > 0;
                    showSweetAlertToast(
                        t("common.success"),
                        `${t("employee.contract.contractNote")} ${t(
                            `common.actions.${
                                isEdit
                                    ? "updatedSuccessfully"
                                    : "createdSuccessfully"
                            }`
                        )}`,
                        "success"
                    );
                    onChange(
                        isEdit
                            ? value.map((x) => {
                                  return x.Id == state.Id
                                      ? getParsedEmployeeContractNote(res.Data)
                                      : x;
                              })
                            : [
                                  ...value,
                                  getParsedEmployeeContractNote(res.Data),
                              ]
                    );
                    setState({ ...state, Id: undefined, Note: "" });
                    queryClient.invalidateQueries(
                        getBusinessEmployeeContractServiceKey("get", {
                            contractId,
                        })
                    );
                } else if (res?.Errors) {
                    showSweetAlertToast(
                        t("common.error.error"),
                        res?.Message
                            ? res.Message
                            : t(
                                  `common.actions.errors.${
                                      state.Id
                                          ? "unableToUpdate"
                                          : "unableToCreate"
                                  }`
                              ),
                        "error"
                    );
                } else {
                    showUnexpectedErrorToast();
                }
            },
        }
    );

    const validityStateManager = useMemo(() => {
        const validityState = state
            ? validateEmployeeContractNote(state)
            : getInitializedValidityState();

        return new ValidityStateManager(validityState);
    }, [state, t, updateResponse]);
    return (
        <div className={styles.root}>
            {showTitle && (
                <div className={styles.title}>
                    {t("employee.contract.notes.text")}
                </div>
            )}
            <div className={styles.header}>
                <div className={styles.field}>
                    <AppInputField
                        value={state.Note || ""}
                        onValueChange={(val) => {
                            setState({ ...state, Note: val || "" });
                        }}
                        onKeyDown={(e: any) => {
                            if (
                                e.keyCode == 13 &&
                                !e.shiftKey &&
                                !createUpdateLoading &&
                                state.Note.length > 0
                            ) {
                                e.preventDefault();
                                e.stopPropagation();

                                createUpdate();
                                return false;
                            }
                            return false;
                        }}
                        showEmptyError={true}
                        error={validityStateManager.getFirstErrorInfo("Note")}
                    />
                </div>
                <AppButton
                    disabled={
                        !permMap.EDIT ||
                        createUpdateLoading ||
                        !validityStateManager.isStateValid()
                    }
                    onClick={() => {
                        createUpdate();
                    }}
                    className={styles.btn}
                >
                    {t("employee.contract.notes.saveNote")}
                </AppButton>
            </div>
            <div className={styles.list}>
                {value.map((n, idx) => {
                    return (
                        <div key={idx} className={styles.item}>
                            <div className={styles.header}>
                                <div>
                                    <span className={`${styles.dotWrapper}`}>
                                        <span></span>
                                    </span>
                                    {n.UserName}
                                </div>
                                <div>
                                    {n.CreatedAt.format(
                                        getDateTimeDisplayFormatForLocale()
                                    )}
                                </div>
                            </div>
                            <div className={styles.data}>
                                <div>{n.Note}</div>
                                <div className={styles.icons}>
                                    <AppIconButton
                                        onClick={() =>
                                            setState({
                                                ...state,
                                                Note: n.Note,
                                                Id: n.Id,
                                            })
                                        }
                                        buttonType="Edit"
                                        readonly={!permMap.EDIT || deleting}
                                    />
                                    <AppDeleteButton
                                        onClick={() =>
                                            deleteNote(n.Id as number)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default EmployeeContractNotes;
