import {
    GenerateAccountingExcelRequest,
    IFile,
    Optional,
    SelectItem,
} from "models/general";
import {
    KindergartenClientContactDeleteRequest,
    KindergartenClientContactInfo,
    KindergartenClientRequest,
} from "models/kindergartenClient";
import routesForContext from "routing/AppRoutes";
import { BaseService } from "../BaseService";
import {
    KindergartenClientListResponse,
    KindergartenClientResponse,
} from "models/kindergartenClient/response";
import { serializeDateRangeRequest, AppResponse } from "models/general";
export interface IKindergartenClientService {
    getSiblings(): Promise<AppResponse<SelectItem[]>>;
    addUpdate(
        clientReq: KindergartenClientRequest
    ): Promise<AppResponse<KindergartenClientResponse>>;
    getClient(
        clientId: Optional<string>
    ): Promise<AppResponse<KindergartenClientResponse>>;
    delete(encodedClientId: string): Promise<AppResponse<number>>;
    deleteSibling(id: number): Promise<AppResponse<number>>;
    addSibling(id: number): Promise<AppResponse<SelectItem>>;
    clientContactAddUpdate(
        clientReq: KindergartenClientContactInfo
    ): Promise<AppResponse<KindergartenClientContactInfo>>;
    getCustomerNumber(lastName: string): Promise<AppResponse<string>>;
    deleteClientContact(
        val: KindergartenClientContactDeleteRequest
    ): Promise<AppResponse<number>>;
    getClientsList(
        reqParams?: KindergartenClientRequest
    ): Promise<AppResponse<KindergartenClientListResponse>>;
    generateAccountingExcel(
        reqParams?: GenerateAccountingExcelRequest
    ): Promise<AppResponse<IFile>>;
}

export function getKindergartenClientServiceKey(
    name: keyof IKindergartenClientService,
    data?: any
) {
    if (!data) {
        return name;
    }
    return `kindergartenClients_${name}_${JSON.stringify(data)}`;
}
const apiLinkProvider = routesForContext().kindergarten.clients().api;
type ApiLinkProviderType = typeof apiLinkProvider;

export class KindergartenClientService
    extends BaseService
    implements IKindergartenClientService
{
    public clientsRoutes: ApiLinkProviderType;

    constructor(apiLinkProvider: ApiLinkProviderType) {
        super();
        this.clientsRoutes = apiLinkProvider;
    }

    getSiblings(): Promise<AppResponse<SelectItem[]>> {
        return this.doServerXHR<AppResponse<SelectItem[]>>({
            url: this.clientsRoutes.withId().getNonSiblings(),
            method: "get",
        });
    }
    addUpdate(
        clientReq: KindergartenClientRequest
    ): Promise<AppResponse<KindergartenClientResponse>> {
        return this.doServerXHR<KindergartenClientResponse>({
            url: this.clientsRoutes.addUpdateClient(),
            method: "post",
            data: clientReq,
        });
    }
    generateAccountingExcel(
        req: GenerateAccountingExcelRequest
    ): Promise<AppResponse<IFile>> {
        return this.doServerXHR<IFile>({
            url: this.clientsRoutes.generateAccountingExcel(),
            method: "post",
            data: {
                ...req,
                ...serializeDateRangeRequest(req),
            },
        });
    }
    getClient(
        clientId: Optional<string>
    ): Promise<AppResponse<KindergartenClientResponse>> {
        return this.doServerXHR<KindergartenClientResponse>({
            url: this.clientsRoutes.withId(clientId).getClient(),
            method: "get",
        });
    }
    getClientsList(
        reqParams?: KindergartenClientRequest
    ): Promise<AppResponse<KindergartenClientListResponse>> {
        return this.doServerXHR<AppResponse<KindergartenClientListResponse>>({
            url: this.clientsRoutes.getClientsList(reqParams),
            method: "get",
        });
    }
    clientContactAddUpdate(
        clientReq: KindergartenClientContactInfo
    ): Promise<AppResponse<KindergartenClientContactInfo>> {
        return this.doServerXHR<KindergartenClientContactInfo>({
            url: this.clientsRoutes.withId().clientContactAddUpdate(),
            method: "post",
            data: clientReq,
        });
    }
    delete(encodedClientId: string): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.clientsRoutes.withId(encodedClientId).deleteClient(),
            method: "post",
        });
    }
    deleteSibling(id: number): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.clientsRoutes.withId().deleteSibling(id),
            method: "delete",
        });
    }
    addSibling(siblingClientId: number): Promise<AppResponse<SelectItem>> {
        return this.doServerXHR<SelectItem>({
            url: this.clientsRoutes.withId().addSibling(siblingClientId),
            method: "post",
        });
    }
    getCustomerNumber(lastName: string): Promise<AppResponse<string>> {
        return this.doServerXHR<string>({
            url: this.clientsRoutes.getCustomerNumber({ lastName: lastName }),
            method: "post",
        });
    }
    deleteClientContact(
        val: KindergartenClientContactDeleteRequest
    ): Promise<AppResponse<number>> {
        return this.doServerXHR<number>({
            url: this.clientsRoutes.withId().deleteClientContact({
                contactId: val.contactId,
                updateToPrimary: val.updateToPrimary,
            }),
            method: "post",
        });
    }
}
