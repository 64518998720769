import { AppSelectOld } from "components/AppSelect";
import {
    AppInputField,
    MonthYearSplittedDropdowns,
    YearsFilterDropdown,
} from "components/FormFields";
import { defaultTo, isNil } from "lodash-es";
import { BoolToYesNo, Gender, SelectItem } from "models/general";
import { useTranslation } from "react-i18next";
import { appTableFilterSelectStyles } from "components/Table/AppTable";
import { getUniqueList } from "globals/helpers/generalHelper";
import { useMemo } from "react";
import {
    KindergartenClientRegistrationsFilterSelectOptions,
    KindergartenClientRegistrationFilter,
    ClientRegistrationStatus,
    ClientRegistrationProgressStatus,
    DataSourceTypes,
} from "models/kindergartenClientRegistration";

export interface KindergartenClientRegistrationFilterRowProps {
    value: KindergartenClientRegistrationFilter;
    onChange: (value: KindergartenClientRegistrationFilter) => void;
    filterSelectOptions: KindergartenClientRegistrationsFilterSelectOptions;
}

export const KindergartenClientRegistrationFilterRow: React.FC<
    KindergartenClientRegistrationFilterRowProps
> = ({ value, onChange, filterSelectOptions }) => {
    const { t } = useTranslation();

    const allOption: SelectItem = {
        Text: t("common.all"),
        Value: null,
    };

    const { careScopeOptions } = useMemo(() => {
        return {
            // ageGroupOptions: [
            //     allOption,
            //     ...filterSelectOptions.AgeGroups,
            // ] as SelectItem[],
            careScopeOptions: [allOption, ...filterSelectOptions.CareScopes],
        };
    }, [filterSelectOptions, allOption]);
    const {
        statusOptions,
        genderOptions,
        booleanOptions,
        progressOptions,
        sourceOptions,
    } = useMemo(() => {
        return {
            statusOptions: [
                allOption,
                ...[
                    ...Object.values(ClientRegistrationStatus).map((x) => {
                        return {
                            Text: t(`common.status.${x.toString()}`),
                            Value: x.toString(),
                        } as SelectItem;
                    }),
                ],
            ],
            progressOptions: [
                allOption,
                ...[
                    ...Object.values(ClientRegistrationProgressStatus).map(
                        (x) => {
                            return {
                                Text: t(
                                    `kindergartenClient.registration.progressStatus.${x.toString()}`
                                ),
                                Value: x.toString(),
                            } as SelectItem;
                        }
                    ),
                ],
            ],
            genderOptions: [
                allOption,
                ...Object.values(Gender).map((x) => {
                    return {
                        Text: t(`common.gender.${x.toString()}`),
                        Value: x,
                    } as SelectItem;
                }),
            ],
            sourceOptions: [
                allOption,
                ...Object.values(DataSourceTypes).map((x) => {
                    return {
                        Text: t(`common.sourceTypes.${x.toString()}`),
                        Value: x,
                    } as SelectItem;
                }),
            ],
            booleanOptions: [
                allOption,
                ...Object.values(BoolToYesNo)
                    .filter((x) => typeof x === "number")
                    .map((x) => {
                        return {
                            Text: t(`common.${x === 1 ? "yes" : "no"}`),
                            Value: x.toString(),
                        } as SelectItem;
                    }),
            ],
        };
    }, [t, allOption]);

    return (
        <tr className="filters-row">
            <td>
                <AppInputField
                    showClearButton={true}
                    value={value.Name}
                    onValueChange={(val) => {
                        onChange({ ...value, Name: val });
                    }}
                />
            </td>
            <td>
                <AppSelectOld
                    menuPortalTarget={document.body}
                    styles={appTableFilterSelectStyles}
                    options={genderOptions}
                    value={
                        value.Gender
                            ? genderOptions.find((x) => x.Value == value.Gender)
                            : allOption
                    }
                    showEmptyError={true}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    onChange={(e: SelectItem) =>
                        onChange({
                            ...value,
                            Gender: e.Value as Gender,
                        })
                    }
                />
            </td>
            <td>
                <YearsFilterDropdown
                    styles={appTableFilterSelectStyles}
                    options={getUniqueList(filterSelectOptions.Years, "Value")}
                    value={value.Year}
                    onChange={(val?: number) => {
                        onChange({
                            ...value,
                            Year: val,
                        });
                    }}
                />
            </td>
            <td>
                <MonthYearSplittedDropdowns
                    value={{
                        month: value.BirthdayMonth,
                        year: value.BirthdayYear,
                    }}
                    yearOptions={filterSelectOptions.BirthdayYears}
                    onChange={(v: { month?: number; year?: number }) => {
                        onChange({
                            ...value,
                            BirthdayMonth: v.month,
                            BirthdayYear: v.year,
                        });
                    }}
                />
            </td>
            <td>
                <MonthYearSplittedDropdowns
                    value={{
                        month: value.CareStartMonth,
                        year: value.CareStartYear,
                    }}
                    yearOptions={filterSelectOptions.CareStartYears}
                    onChange={(v: { month?: number; year?: number }) => {
                        onChange({
                            ...value,
                            CareStartMonth: v.month,
                            CareStartYear: v.year,
                        });
                    }}
                />
            </td>
            {/* <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(value.AgeGroupShortName)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    //loading={loading}
                    styles={appTableFilterSelectStyles}
                    options={getUniqueList(ageGroupOptions, "Value")}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={
                        value.AgeGroupShortName
                            ? ageGroupOptions.find(
                                  (x) => x.Text === value.AgeGroupShortName
                              )
                            : allOption
                    }
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) =>
                        e &&
                        onChange({
                            ...value,
                            AgeGroupFieldUuid: e.Value as string,
                            AgeGroupShortName: e.Value
                                ? (e.Text as string)
                                : undefined,
                        })
                    }
                />
            </td> */}
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(value.CareScopeFieldName)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    //loading={loading}
                    styles={appTableFilterSelectStyles}
                    options={getUniqueList(careScopeOptions, "Value")}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={
                        value.CareScopeFieldName
                            ? careScopeOptions.find(
                                  (x) => x.Text === value.CareScopeFieldName
                              )
                            : allOption
                    }
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) =>
                        e &&
                        onChange({
                            ...value,
                            CareScopeFieldUuid: e.Value as string,
                            CareScopeFieldName: e.Value
                                ? (e.Text as string)
                                : undefined,
                        })
                    }
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(value.Status)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    options={statusOptions}
                    styles={appTableFilterSelectStyles}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={
                        isNil(value.Status)
                            ? allOption
                            : statusOptions.find(
                                  (x) => x.Value === value.Status
                              )
                    }
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onChange({
                                ...value,
                                Status: defaultTo(
                                    e.Value as ClientRegistrationStatus,
                                    null
                                ),
                            });
                        }
                    }}
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(value.CountryCode)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    options={filterSelectOptions.Countries}
                    styles={appTableFilterSelectStyles}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={
                        isNil(value.CountryCode)
                            ? allOption
                            : filterSelectOptions.Countries.find(
                                  (x) => x.Value === value.CountryCode
                              )
                    }
                    onChange={(e: SelectItem) => {
                        onChange({
                            ...value,
                            CountryCode: e
                                ? defaultTo(
                                      e.Value as ClientRegistrationStatus,
                                      null
                                  )
                                : undefined,
                        });
                    }}
                />
            </td>
            {/* <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(value.ProgressStatus)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    options={progressOptions}
                    styles={appTableFilterSelectStyles}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={
                        isNil(value.ProgressStatus)
                            ? allOption
                            : progressOptions.find(
                                  (x) => x.Value === value.ProgressStatus
                              )
                    }
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onChange({
                                ...value,
                                ProgressStatus: defaultTo(
                                    e.Value as ClientRegistrationProgressStatus,
                                    null
                                ),
                            });
                        }
                    }}
                />
            </td> */}
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(value.IsSibling)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    styles={appTableFilterSelectStyles}
                    value={
                        isNil(value.IsSibling)
                            ? allOption
                            : booleanOptions
                                  .filter((x) => x.Value != null)
                                  .find(
                                      (x) =>
                                          Boolean(Number(x.Value)) ===
                                          value.IsSibling
                                  )
                    }
                    options={booleanOptions}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onChange({
                                ...value,
                                IsSibling:
                                    e.Value != null
                                        ? Boolean(Number(e.Value))
                                        : null,
                            });
                        }
                    }}
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(value.ArrangedPlace)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    styles={appTableFilterSelectStyles}
                    value={
                        isNil(value.ArrangedPlace)
                            ? allOption
                            : booleanOptions
                                  .filter((x) => x.Value != null)
                                  .find(
                                      (x) =>
                                          Boolean(Number(x.Value)) ===
                                          value.ArrangedPlace
                                  )
                    }
                    options={booleanOptions}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onChange({
                                ...value,
                                ArrangedPlace:
                                    e.Value != null
                                        ? Boolean(Number(e.Value))
                                        : null,
                            });
                        }
                    }}
                />
            </td>
            <td>
                <AppSelectOld
                    showDropdownIndicator={isNil(value.FromSource)}
                    showCustomClearIndicator={true}
                    menuPortalTarget={document.body}
                    options={sourceOptions}
                    styles={appTableFilterSelectStyles}
                    getOptionLabel={(opt: SelectItem) => opt.Text}
                    getOptionValue={(opt: SelectItem) => opt.Value}
                    value={
                        isNil(value.FromSource)
                            ? allOption
                            : sourceOptions.find(
                                  (x) => x.Value === value.FromSource
                              )
                    }
                    onChange={(e: SelectItem) => {
                        if (e) {
                            onChange({
                                ...value,
                                FromSource: defaultTo(
                                    e.Value as DataSourceTypes,
                                    null
                                ),
                            });
                        }
                    }}
                />
            </td>
            <td></td>
            <td></td>
        </tr>
    );
};
export default KindergartenClientRegistrationFilterRow;
