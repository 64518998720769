import { ISO8601_DATE_FORMAT } from "globals/constants";
import { getGenderNumberToEnumValue } from "globals/helpers/generalHelper";
import { defaultTo, isNil } from "lodash-es";
import { SelectItem } from "models/general";
import moment from "moment";
import {
    getClientStateEnumByNumberValue,
    getKindergartenClientContactInitialValues,
} from "./helper";
import {
    KindergartenClient,
    KindergartenClientBase,
    KindergartenClientContactInfo,
    KindergartenClientListing,
    KindergartenClientListingBase,
} from "./model";

export interface KindergartenClientListingResponse
    extends KindergartenClientListingBase {
    Birthday: string;
    StartOfCare: string;
    EndOfContract: string;
}

export interface KindergartenClientResponse extends KindergartenClientBase {
    Notes: string;
    AgeGroup: number;
    SchoolStart?: string;
    CareBeginning?: string;
    EndOfCOntract?: string;
    Birthday: string;
    CustomerNumber: string;
    ShorthandSymbol: string;
    PrimaryContact: KindergartenClientContactInfo;
    SecondaryContact: KindergartenClientContactInfo;
    Provision: boolean;
    KindergartenId: number;
    Siblings?: SelectItem[];
}

export interface KindergartenClientFilterSelectOptions {
    Years?: SelectItem[];
    BirthdayYears: SelectItem[];
    CareStartYears: SelectItem[];
    CareEndYears: SelectItem[];
}
export interface KindergartenClientListResponse {
    Records: KindergartenClientListingResponse[];
    FilterOptions: KindergartenClientFilterSelectOptions;
}

export const getParsedClients = (
    val: KindergartenClientListingResponse
): KindergartenClientListing => {
    return {
        ...val,
        EndOfContract: val.EndOfContract
            ? moment(val.EndOfContract, ISO8601_DATE_FORMAT)
            : null,
        Birthday: val.Birthday
            ? moment(val.Birthday, ISO8601_DATE_FORMAT)
            : null,
        StartOfCare: val.StartOfCare
            ? moment(val.StartOfCare, ISO8601_DATE_FORMAT)
            : null,
    } as KindergartenClientListing;
};

export const parseKindergartenClientResponseToClient = (
    client: KindergartenClientResponse
): KindergartenClient => {
    let contacts: KindergartenClientContactInfo[] = [];
    const response: KindergartenClient = {
        ...client,
        Contacts: [],
        Siblings: defaultTo(client.Siblings, []),
        Birthday: moment(client.Birthday, ISO8601_DATE_FORMAT),
        CareBeginning: client.CareBeginning
            ? moment(client.CareBeginning, ISO8601_DATE_FORMAT)
            : null,
        EndOfCOntract: client.EndOfCOntract
            ? moment(client.EndOfCOntract, ISO8601_DATE_FORMAT)
            : null,
        SchoolStart: client.SchoolStart ? moment(client.SchoolStart) : null,
        Gender: !isNil(client.Gender)
            ? getGenderNumberToEnumValue(Number(client.Gender))
            : null,
        Status: !isNil(client.Status)
            ? getClientStateEnumByNumberValue(Number(client.Status))
            : null,
    };
    const parseContact = (contact: KindergartenClientContactInfo) => ({
        ...contact,
        ...(!isNil(contact.Gender)
            ? {
                  Gender: getGenderNumberToEnumValue(Number(contact.Gender)),
              }
            : {}),
        ClientId: client.Id as number,
    });
    if (client.PrimaryContact) {
        contacts = [parseContact(client.PrimaryContact)];
    } else {
        contacts = [
            {
                ...getKindergartenClientContactInitialValues(
                    client.Id as number
                ),
                IsPrimaryContact: true,
            },
        ];
    }
    if (client.SecondaryContact) {
        contacts = [...contacts, parseContact(client.SecondaryContact)];
    } else {
        contacts = [
            ...contacts,
            {
                ...getKindergartenClientContactInitialValues(
                    client.Id as number
                ),
            },
        ];
    }

    response.Contacts = contacts;

    return response;
};
