import { AppSelectOld } from "components/AppSelect";
import ErrorMessage from "components/ErrorMessage";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { useRouting } from "hooks/general/routing";
import { ErrorInfo, Optional, SelectItem } from "models/general";
import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { GeneralService, getGeneralServiceKey } from "services";

export interface CountriesDropdownProps {
    colSize?: number;
    label?: string;
    value: Optional<string>;
    styles?: { root?: any; select?: any };
    className?: string;
    readonly?: boolean;
    showLabel?: boolean;
    onChange: (client: any) => void;
    error?: ErrorInfo;
}
const selectStyles = {
    input: (base: any) => ({
        ...base,
        minHeight: "32px",
        display: "flex",
        alignItems: "center",
    }),
};

export const CountriesDropdown: React.FC<CountriesDropdownProps> = ({
    label,
    value,
    showLabel = true,
    readonly,
    className,
    error,
    styles = {
        select: selectStyles,
    },
    onChange: onValueChange,
}) => {
    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();
    const { linkProvider } = useRouting();
    const generalService = new GeneralService(linkProvider.api.general);

    const pleaseSelectOption: SelectItem = {
        Text: t("common.pleaseSelect"),
        Value: null,
    };

    const {
        isFetching: loadingCountries,
        refetch: refetchCountries,
        data: countries,
    } = useQuery(
        getGeneralServiceKey("getCountries", { appLocale }),
        async () => {
            return await generalService.getCountries(appLocale);
        },
        {
            enabled: true,
            select: (resp) => {
                return [pleaseSelectOption, ...(resp?.Data ? resp?.Data : [])];
            },
        }
    );
    useEffect(() => {
        if (!loadingCountries && !countries) {
            refetchCountries();
        }
    }, [appLocale]);

    return (
        <Form.Group className={className} style={styles.root}>
            {showLabel && <Form.Label>{label}</Form.Label>}
            <AppSelectOld
                menuPortalTarget={document.body}
                styles={styles.select}
                options={countries}
                isDisabled={readonly}
                value={
                    value
                        ? countries?.find((x) => x.Value === value)
                        : pleaseSelectOption
                }
                getOptionLabel={(opt: SelectItem) => opt.Text}
                getOptionValue={(opt: SelectItem) => opt.Value}
                onChange={(e: SelectItem) => onValueChange(e.Value)}
            />
            {error && <ErrorMessage showEmpty={true} errorInfo={error} />}
        </Form.Group>
    );
};
