export const EmployeeEnTranslations = {
    employee: {
        area: "Area",
        createTitle: {
            candidates: "Create Candidates",
            suppliers: "Create Suppliers",
            employees: "Create Employees",
        },
        title: {
            candidates: "Candidates",
            suppliers: "Suppliers",
            employees: "Employees",
        },
        tabs: {
            basicData: "Base Data",
            accessControl: "Access Control",
            bankData: "Bank Data",
            diseases: "Diseases",
            vacations: "Vacations",
            documents: "Documents",
            shifts: "Shifts",
            salaryStatements: "Salary Statements",
            contractData: "Contract Data",
            school: "School",
            absences: "Absences", // old name - School
            careGroups: "Care Groups",
            workingGroups: "Working Groups",
            app: "App",
        },
        connectedToApp: "App",
        contractEnd: "Contract End",
        contractStart: "Contract Start",
        createNewCandidate: "New Candidate",
        createNewEmployee: "New Contract",
        createNewSupplier: "New Supplier",
        eligibleStaff: "Eligible Staff",
        employeeFunction: "Employee Function",
        endOfTrialPeriod: "End of Trial Period",
        expirationDate: "Expiration Date",
        firstName: "First Name",
        fullName: "Full Name",
        grossSalary: "Gross Salary",
        group: "Group",
        holidays: "Holidays",
        id: "ID",
        lastName: "Last Name",
        status: "Status",
        totalCandidates: "Total Candidates:",
        totalEmployees: "Total Employees:",
        totalSuppliers: "Total Suppliers:",
        weeklyHours: "Weekly Hours",
        shift: {
            name: "Shift",
            createTitle: "Create Shift",
            editTitle: "Edit Shift",
            forSelectedDays: "For Selected Days?",
            type: {
                name: "Type",
                missing: "Type is required.",
            },
            days: {
                name: "For Days",
                missing: "Days selection is required.",
            },
            typeOptions: { exclusion: "Exclude", preference: "Include" },
            shift: {
                missing: "Shift is required.",
                name: "Shift",
            },
            excluded: "Excluded",
            included: "Included",
        },
        vacations: {
            title: "Employee Vacations",
            vacation: "Vacation",
            createTitle: "Create Vacation",
            editTitle: "Edit Vacation",
            comment: "Comment",
            startDate: "Start Date",
            endDate: "End Date",
            total: "Total",
            dismantlingOvertime: "Dismantling Overtime",
            manualCorrection: "Manual Correction",
            halfDayVacation: "Half day Vacation",
            halfDayVacationAsGift: "Half day Vacation given as gift",
            unpaidVacation: "Unpaid Vacation",
            status: {
                name: "Status",
                requested: "Requested",
                approved: "Approved",
                declined: "Declined",
            },
            totals: {
                totalVacationsForYear:
                    "Entitled to <strong>{{count}}</strong> days of vacations for an entire year",
                totalAllowed:
                    "Entitled to <strong>{{count}}</strong> vacation days (this year)",
                additionalVacations:
                    "Additional <strong>{{count}}</strong> days (from last year)",
                manualCorrections:
                    "Manually added <strong>{{count}}</strong> (this year)",
                totalUsed:
                    "<strong>{{count}}</strong> vacation days are requested (this year)",
                remainingVacations:
                    "<strong>{{count}}</strong> days remaining for year {{year}}",
            },
        },
        contract: {
            contractNote: "Note",
            notes: {
                manageNotes: "Manage Notes",
                text: "Contract Notes",
                saveNote: "Save Note",
            },
            employeeContract: "Employee Contract",
            contractStart: "Contract Start",
            contractEnd: "Contract End",
            monthsTrialPeriod: "Months Trial Period",
            endOfTrialPeriod: "End of Trial Period",
            monthsExpiration: "Months Expiration",
            expirationDate: "Expiration Date",
            realWeeklyHours: "Real Weekly Hours",
            hoursPerWeek: "Fictitious Hours",
            workingDaysAWeek: "Working Days A Week",
            pauseInMinutes: "Break Per Day (Minutes)",
            createEdit: {
                create: "Create Contract Data",
                edit: "Edit Contract Data",
                holidayPerYear: "Holiday Per Year",
                hourlyNetSalary: "Hourly Net Salary",
                hourlySalaryGross: "Hourly Salary Gross",
                salaryFictitiousHours: "Salary Fictitious Hours",
                realGrossSalary: "Real Gross Salary",
                chargeable: "Chargeable",
                useContract: "Use this contract for printing",
                ignoreFromDashboard: "Ignore this employee on dashboard",
                workingHoursToolTipLine1:
                    "If you create an employment contract with few working hours and need fictitious comparison calculation, you can enter a number of hours here.",
                workingHoursToolTipLine2:
                    "The fictitious salary will then be calculated automatically to the fictitious number of hours.",
                area: {
                    name: "Working Area",
                    missing: "Area is required",
                },
                function: {
                    name: "Employee Function",
                    missing: "Function is required",
                },
            },
        },
    },
};

export default EmployeeEnTranslations;
