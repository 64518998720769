import { AppTable, AppTableSkeleton } from "components/Table";
import { useRouting } from "hooks/general/routing";
import { ResponseMeta, sortData, SortOrder, TableSort } from "models/general";
import {
    ClientRegistrationSortColumn,
    ClientRegistrationStatus,
    KindergartenClientRegistrationsFilterSelectOptions,
} from "models/kindergartenClientRegistration";
import {
    getKindergartenClientRegistrationFromResponse,
    getKindergartenClientRegistrationRequest,
    getKindergartenClientRegistrationSortColumnKeyFromEnum,
} from "models/kindergartenClientRegistration/helper";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
    getKindergartenClientRegistrationServiceKey,
    KindergartenClientRegistrationService,
} from "services/kindergarten/KindergartenClientRegistrationService";
import { useCheckPermission } from "hooks/permissionCheck";
import { Kindergarten_Client_ExternalRegistrations } from "models";
import { useNavigate } from "react-router";
import KindergartenClientRegistrationFilterRow from "screens/kindergarten/clientRegistration/partials/table/KindergartenClientRegistrationFilterRow";
import {
    KindergartenClientRegistrationHeaderRow,
    KindergartenClientRegistrationRow,
} from "screens/kindergarten/clientRegistration/partials";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import InfiniteScroll from "react-infinite-scroll-component";
import { defaultTo } from "lodash-es";
import { AppTableTotal } from "commonPartials/AppTableTotal";
import {
    KindergartenClientRegistration,
    KindergartenClientRegistrationFilter,
} from "models/kindergartenClientRegistration/model";
import styles from "./KindergartenClientRegistration.module.scss";
import classNames from "classnames";
import {
    getFilteredArray,
    getMillisecondsForMinutes,
} from "globals/helpers/generalHelper";
import { useWebEventHub } from "hooks/layout/useWebEventHub";
import { WebEventType } from "models/notification";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { LONG_DATA_CACHE_MINUTES } from "globals/constants";

const equalityCheckKeysList: (keyof KindergartenClientRegistration)[] = [
    "Gender",
    "Status",
    "IsSibling",
    "FromSource",
    "ArrangedPlace",
    "CountryCode",
    "BirthdayMonth",
    "BirthdayYear",
    "CareStartMonth",
    "CareStartYear",
];

const compareOnlyMonths: (keyof KindergartenClientRegistration)[] = [];

interface ClientRegistrationState {
    filter: KindergartenClientRegistrationFilter;
    sort: TableSort<ClientRegistrationSortColumn>;
    list?: KindergartenClientRegistration[];
    toRenderData: KindergartenClientRegistration[];
    fullData: KindergartenClientRegistration[];
    filterSelectOptions: KindergartenClientRegistrationsFilterSelectOptions;
    total: number;
    meta: ResponseMeta;
    ready: boolean;
}
const PAGE_SIZE = 100;
const COLUMNS_COUNT = 13;

const sortList = (
    data: KindergartenClientRegistration[],
    sort: TableSort<ClientRegistrationSortColumn>
) =>
    sortData(data, [
        {
            col: getKindergartenClientRegistrationSortColumnKeyFromEnum(
                sort.SortColumn
            ),
            dir: sort.SortOrder,
        },
        {
            col: getKindergartenClientRegistrationSortColumnKeyFromEnum(
                ClientRegistrationSortColumn.Name
            ),
            dir: sort.SortOrder,
        },
    ]) as KindergartenClientRegistration[];

export const KindergartenClientRegistrationList: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { signalR } = useWebEventHub();
    const { getPermissionMap } = useCheckPermission();
    const { id: sessionBusinessId } = useSessionBusiness();
    const permMap = getPermissionMap(Kindergarten_Client_ExternalRegistrations);

    const { linkProvider } = useRouting();
    const linkProviderBase =
        linkProvider.kindergarten.clients().screens.registrations;

    const registrationService = new KindergartenClientRegistrationService(
        linkProvider.kindergarten.clients().api.clientRegistration
    );

    const [state, setState] = useState<ClientRegistrationState>({
        filter: {
            Status: ClientRegistrationStatus.Active,
        } as KindergartenClientRegistrationFilter,
        sort: {
            SortColumn: ClientRegistrationSortColumn.CreatedAt,
            SortOrder: SortOrder.DESC,
        },
        ready: false,
        filterSelectOptions: {
            AgeGroups: [],
            CareScopes: [],
            Countries: [],
            CareStartYears: [],
            BirthdayYears: [],
            Years: [
                {
                    Text: new Date().getFullYear().toString(),
                    Value: new Date().getFullYear(),
                },
            ],
        },
        fullData: [],
        list: undefined,
        toRenderData: [],
        total: 0,
        meta: {
            PageNumber: 1,
            PageSize: PAGE_SIZE,
            HasNextPage: false,
        } as ResponseMeta,
    });

    const scrollContainer = useRef<HTMLDivElement | null>(null);
    const listRequest = getKindergartenClientRegistrationRequest(
        state.filter,
        state.sort,
        undefined,
        true
    );

    const {
        isLoading: loading,
        isRefetching: refetching,
        isFetching: fetching,
        data: listResponse,
        refetch,
    } = useQuery(
        getKindergartenClientRegistrationServiceKey("list", listRequest),
        async () => await registrationService.list(listRequest),
        {
            enabled: true,
            // staleTime: getMillisecondsForMinutes(LONG_DATA_CACHE_MINUTES),
            cacheTime: getMillisecondsForMinutes(LONG_DATA_CACHE_MINUTES),
        }
    );
    useEffect(() => {
        if (signalR) {
            signalR.on("eventReceived", (event: any) => {
                // used any, because signalR gives objects in camel case in response
                if (
                    sessionBusinessId == event.businessId &&
                    event.type ==
                        WebEventType.Refresh_Kindergarten_Client_Registration_List
                ) {
                    refetch();
                }
            });
        }
    }, []);

    useEffect(() => {
        if (!loading && listResponse?.Meta) {
            const convertedData = listResponse.Data.Records.map((x) =>
                getKindergartenClientRegistrationFromResponse(x)
            );
            setState({
                ...state,
                ready: true,
                fullData:
                    listResponse.Meta.PageNumber == 1
                        ? convertedData
                        : [...defaultTo(state.fullData, []), ...convertedData],
                filterSelectOptions: listResponse.Data.FilterOptions,
                meta: listResponse.Meta,
                total: listResponse.Meta.TotalItemCount as number,
            });
        }
    }, [refetching, loading, listResponse]);

    // useEffect(() => {
    //     if (!refetching) {
    //         refetch();
    //     }
    // }, [state.meta.PageNumber]);

    const { mutate: deleteRegistration, isLoading: deleting } = useMutation(
        async (id: string) => await registrationService.delete(id),
        {
            onSuccess: (res) => {
                const result = defaultTo(state.list, []).filter(
                    (x) => x.Id == res.Data
                );
                if (result.length > 0) {
                    const toDelete = result[0];
                    showSweetAlertToast(
                        t("common.success"),
                        `"${toDelete.Name}" ${t(
                            "common.actions.deletedSuccessfully"
                        )}`,
                        "success"
                    );
                    setState((old) => ({
                        ...old,
                        total: Math.max(0, old.total - 1),
                        list: defaultTo(old.list, []).filter(
                            (x) => x.Id != res.Data
                        ),
                    }));
                }
            },
            onError: () => {
                showSweetAlertToast(
                    t("common.error.error"),
                    t("common.actions.errors.unableToDelete"),
                    "error"
                );
            },
        }
    );

    const onSortChange = (
        sortColumn: ClientRegistrationSortColumn,
        sortOrder: SortOrder
    ) => {
        setState({
            ...state,
            meta: {
                ...state.meta,
                PageNumber: 1,
            },
            sort: {
                ...state.sort,
                SortColumn: sortColumn,
                SortOrder: sortOrder,
            },
        });
    };
    const filterData = useCallback(
        (
            value?: KindergartenClientRegistrationFilter,
            data?: KindergartenClientRegistration[]
        ) => {
            const fullData = defaultTo(data, state.fullData);

            if (fullData) {
                const { ...filters } = defaultTo(value, state.filter);

                // const namedFilterData = Name
                //     ? fullData.filter(
                //           (x) =>
                //               x.Name?.trim()
                //                       .toLocaleLowerCase()
                //                       .includes(Name.toLocaleLowerCase())
                //               )
                //       )
                //     : fullData;

                const filteredData = getFilteredArray(
                    fullData,
                    filters,
                    equalityCheckKeysList,
                    compareOnlyMonths
                );

                return filteredData.length > 0
                    ? sortList(filteredData, state.sort)
                    : [];
            }
            return [];
        },
        [state.fullData, state.sort, state.filter]
    );
    useEffect(() => {
        if (state.ready) {
            const data = filterData(state.filter, state.fullData);

            setState({
                ...state,
                meta: {
                    ...state.meta,
                    PageNumber: 1,
                },
                list: data,
                toRenderData: data.slice(0, PAGE_SIZE),
            });
        }
    }, [state.ready, state.fullData, state.filter, state.sort, filterData]);

    const page = defaultTo(state.meta?.PageNumber, 1);
    const hasNextPage =
        Math.ceil((defaultTo(state.list, []).length * 1.0) / PAGE_SIZE) > page;

    return (
        <div className={styles.root}>
            <div className={styles.content}>
                <InfiniteScroll
                    className={styles.infiniteScroll}
                    dataLength={state.toRenderData.length}
                    scrollThreshold={0.9}
                    next={() => {
                        if (hasNextPage) {
                            const newPage =
                                defaultTo(state.meta.PageNumber, 1) + 1;
                            setState((old) => ({
                                ...old,
                                meta: {
                                    ...old.meta,
                                    PageNumber: newPage,
                                },
                                toRenderData: defaultTo(old.list, []).slice(
                                    0,
                                    Math.min(
                                        newPage * PAGE_SIZE,
                                        defaultTo(old.list, []).length
                                    )
                                ),
                            }));
                        }
                    }}
                    loader={<></>}
                    hasMore={hasNextPage}
                    scrollableTarget="scrollableDiv"
                >
                    <AppTable
                        id="scrollableDiv"
                        tableClass={classNames("table-striped")}
                        hover={true}
                        ref={scrollContainer}
                        stickyHeader={true}
                    >
                        <thead>
                            <KindergartenClientRegistrationHeaderRow
                                value={state.sort}
                                onChange={(
                                    key: ClientRegistrationSortColumn,
                                    order: SortOrder
                                ) => onSortChange(key, order)}
                            />
                        </thead>
                        <tbody>
                            <KindergartenClientRegistrationFilterRow
                                value={state.filter}
                                filterSelectOptions={state.filterSelectOptions}
                                onChange={(filter) =>
                                    setState({
                                        ...state,
                                        filter: filter,
                                        meta: {
                                            ...state.meta,
                                            PageNumber: 1,
                                        },
                                    })
                                }
                            />
                            <tr className="dummy-row">
                                <td colSpan={COLUMNS_COUNT} />
                            </tr>

                            {state.toRenderData &&
                                state.toRenderData.length == 0 &&
                                state.toRenderData.length ==
                                    defaultTo(state.list, []).length &&
                                !loading &&
                                !fetching && (
                                    <tr>
                                        <td
                                            colSpan={COLUMNS_COUNT}
                                            style={{ textAlign: "center" }}
                                        >
                                            {t("common.noDataFound")}
                                        </td>
                                    </tr>
                                )}

                            {state.toRenderData.length > 0 &&
                                state.toRenderData.map((b, idx) => (
                                    <KindergartenClientRegistrationRow
                                        key={`${idx}_${b.Id}`}
                                        value={b}
                                        permMap={permMap}
                                        onClick={(id) => {
                                            navigate(linkProviderBase.edit(id));
                                        }}
                                        onChange={(v) => {
                                            setState((s) => ({
                                                ...s,
                                                fullData: (
                                                    s.fullData || []
                                                ).map((o) => {
                                                    return o.Id == b.Id ? v : o;
                                                }),
                                            }));
                                            // refetch();
                                        }}
                                        loading={deleting}
                                        onDeleteClick={(id) =>
                                            deleteRegistration(id)
                                        }
                                    />
                                ))}
                            {(loading || fetching || !state.list) && (
                                <AppTableSkeleton
                                    columns={COLUMNS_COUNT}
                                    rows={
                                        defaultTo(state.toRenderData, [])
                                            .length > 0
                                            ? 2
                                            : 12
                                    }
                                />
                            )}
                            <tr className="dummy-row">
                                <td colSpan={COLUMNS_COUNT} />
                            </tr>
                        </tbody>
                    </AppTable>
                </InfiniteScroll>
            </div>
            <AppTableTotal
                text={t("kindergartenClient.registration.totalRecords")}
                count={state.toRenderData.length}
                total={defaultTo(state.list, []).length}
            />
        </div>
    );
};
export default KindergartenClientRegistrationList;
