import {
    getInitializedValidityStateFromRules,
    ValidationRules,
    Validations,
} from "globals/helpers/validationHelpers";
import { EmployeeContract } from "./model";
import { getInitializedValidityState } from "models/general";
import { t } from "i18next";
import { EmployeeContractNoteRequest } from "models/employeeContract/request";

export function validateEmployeeContractNote(
    data: EmployeeContractNoteRequest,
    state = getInitializedValidityState([], [])
) {
    const rules: ValidationRules<keyof EmployeeContractNoteRequest> = {
        Note: [
            {
                rule: Validations.REQUIRED,
            },
        ],
    };

    state = getInitializedValidityStateFromRules(rules, data, state);

    return state;
}
export function validateEmployeeContract(
    template: EmployeeContract,
    state = getInitializedValidityState([], [])
) {
    const positiveNumValidations = [
        {
            rule: Validations.OPTIONAL_NUMBER,
        },
        {
            rule: Validations.MIN,
            message: t("common.errorMessages.positiveNum"),
            options: {
                value: 0,
            },
        },
    ];
    const contractDataValidationRules: ValidationRules<keyof EmployeeContract> =
        {
            MonthsTrialPeriod: positiveNumValidations,
            MonthsExpiration: positiveNumValidations,
            RealWeeklyHours: positiveNumValidations,
            RealGrossSalary: positiveNumValidations,
            HoursPerWeek: positiveNumValidations,
            Holidays: positiveNumValidations,
            WorkingDaysAWeek: positiveNumValidations,
            PauseInMinutes: positiveNumValidations,
            HourlySalaryNet: positiveNumValidations,
            HourlySalaryGross: positiveNumValidations,
            BusinessFunctionId: [
                {
                    rule: Validations.MIN,
                    message: t("employee.contract.createEdit.function.missing"),
                    options: {
                        value: 1,
                    },
                },
            ],
            BusinessAreaId: [
                {
                    rule: Validations.MIN,
                    message: t("employee.contract.createEdit.area.missing"),
                    options: {
                        value: 1,
                    },
                },
            ],
        };

    state = getInitializedValidityStateFromRules(
        contractDataValidationRules,
        template,
        state
    );

    return state;
}
