import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useQueryClient } from "react-query";
import { useOutletContext } from "react-router";
import { getKindergartenClientRegistrationServiceKey } from "services/kindergarten/KindergartenClientRegistrationService";
import { ClientRegistrationCreateEditBasicData } from "./createEdit";
import { KindergartenClientRegistrationOutletContext } from "./KindergartenClientRegistrationMain";

export const KindergartenClientRegistrationCreate: React.FC = () => {
    const { permMap } =
        useOutletContext<KindergartenClientRegistrationOutletContext>();
    const queryClient = useQueryClient();
    const { sessionBusiness } = useSessionBusiness();

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div>
                <ClientRegistrationCreateEditBasicData
                    isEdit={false}
                    permMap={permMap}
                    onSuccess={() => {
                        // queryClient.invalidateQueries(
                        //     getKindergartenClientRegistrationServiceKey("get")
                        // );
                        queryClient.invalidateQueries(
                            getKindergartenClientRegistrationServiceKey("list")
                        );
                    }}
                    deadLine={sessionBusiness?.BusinessSetting.AgeGroupDeadline}
                />
            </div>
        </div>
    );
};
export default KindergartenClientRegistrationCreate;
