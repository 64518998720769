import { AppLoader } from "components/Loaders";
import { LONG_DATA_CACHE_MINUTES } from "globals/constants";
import {
    getFilteredArray,
    getMillisecondsForMinutes,
} from "globals/helpers/generalHelper";
import { showUnexpectedErrorToast } from "globals/helpers/sweetAlertHelper";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { useRouting } from "hooks/general/routing";
import { useWebEventHub } from "hooks/layout/useWebEventHub";
import { defaultTo } from "lodash-es";
import { Selectable, sortData, SortOrder, TableSort } from "models/general";
import {
    getKindergartenClientsFilterDefaultValue,
    getKindergartenClientSortColumnKeyFromEnum,
    getParsedClients,
    KindergartenClientContext,
    KindergartenClientFilters,
    KindergartenClientListing,
    KindergartenClientSortColumn,
} from "models/kindergartenClient";
import { WebEventType } from "models/notification";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Outlet, useOutletContext } from "react-router";
import {
    getKindergartenClientServiceKey,
    KindergartenClientService,
} from "services/kindergarten/KindergartenClientService";

const equalityCheckKeysList: (keyof KindergartenClientListing)[] = [
    "Gender",
    "Status",
    "IsAppLinked",
    "IsSibling",
    "HasParentRepresentative",
    "BirthdayMonth",
    "BirthdayYear",
    "CareStartMonth",
    "CareStartYear",
    "CareEndMonth",
    "CareEndYear",
];

const compareOnlyMonths: (keyof KindergartenClientListing)[] = [];

export const useKindergartenClientContext = () => {
    return useOutletContext<KindergartenClientContext>();
};

const sortList = (
    data: Selectable<KindergartenClientListing>[],
    sort: TableSort<KindergartenClientSortColumn>
) =>
    sortData(data, [
        {
            col: getKindergartenClientSortColumnKeyFromEnum(sort.SortColumn),
            dir: sort.SortOrder,
        },
        {
            col: getKindergartenClientSortColumnKeyFromEnum(
                KindergartenClientSortColumn.Name
            ),
            dir: sort.SortOrder,
        },
    ]) as KindergartenClientListing[];

export const KindergartenClientsContextProvider: React.FC = () => {
    const [state, setState] = useState<KindergartenClientContext>({
        sort: {
            SortColumn: KindergartenClientSortColumn.DisplayId,
            SortOrder: SortOrder.ASC,
        },
        completeResponse: undefined,
        isRefetching: false,
        clients: null,
        headerFilterOptions: {
            BirthdayYears: [],
            CareEndYears: [],
            CareStartYears: [],
        },
        filters: getKindergartenClientsFilterDefaultValue(),
        setNavigateUsingCompleteResponse: () => {},
        navigateUsingCompleteResponse: true,
        onChangeCompleteResponse: () => {},
        onFilterChange: () => {},
        onSortChange: () => {},
        onChangeClientList: () => {},
    });
    const queryClient = useQueryClient();
    const { encodedId, id: sessionBusinessId } = useSessionBusiness();
    const { linkProvider } = useRouting();
    const { signalR } = useWebEventHub();
    const kindergartenService = new KindergartenClientService(
        linkProvider.kindergarten.clients().api
    );
    const {
        isLoading: loadingClientList,
        isFetching,
        isRefetching,
        data: clientsListResponse,
        refetch: refetchClientList,
        error: clientsFetchError,
    } = useQuery(
        getKindergartenClientServiceKey("getClientsList", encodedId),
        async () => await kindergartenService.getClientsList(),
        {
            cacheTime: getMillisecondsForMinutes(LONG_DATA_CACHE_MINUTES),
        }
    );

    const clientList = useMemo(() => {
        if (clientsListResponse) {
            return clientsListResponse.Data.Records.map((x) =>
                getParsedClients(x)
            );
        }
    }, [clientsListResponse, loadingClientList]);
    const filterData = useCallback(
        (
            value?: KindergartenClientFilters,
            data?: KindergartenClientListing[]
        ) => {
            const fullData = defaultTo(data, clientList);

            if (fullData) {
                const { Name, ...filters } = defaultTo(value, state.filters);

                const namedFilterData = Name
                    ? fullData.filter(
                          (x) =>
                              defaultTo(x.ContactNames, []).some((y) =>
                                  y
                                      .trim()
                                      .toLocaleLowerCase()
                                      .includes(Name.toLocaleLowerCase())
                              ) ||
                              x.Name.trim()
                                  .toLocaleLowerCase()
                                  .includes(Name.toLocaleLowerCase())
                      )
                    : fullData;

                const filteredData = getFilteredArray(
                    namedFilterData,
                    filters,
                    equalityCheckKeysList,
                    compareOnlyMonths
                );

                return filteredData.length > 0
                    ? sortList(filteredData, state.sort)
                    : [];
            }
            return [];
        },
        [clientList, state.sort, state.filters, state.completeResponse]
    );
    useEffect(() => {
        if (!loadingClientList) {
            if (clientList) {
                setState((old) => ({
                    ...old,
                    completeResponse: clientList,
                    clients: filterData(old.filters, clientList),
                }));
            }
            if (clientsFetchError) {
                showUnexpectedErrorToast();
            }
        }
    }, [isFetching, loadingClientList, clientList]);

    useEffect(() => {
        if (signalR) {
            signalR.on("eventReceived", (event: any) => {
                // used any, because signalR gives objects in camel case in response

                if (
                    sessionBusinessId == event.businessId &&
                    event.type ==
                        WebEventType.Refresh_Kindergarten_Client_List &&
                    !isRefetching
                ) {
                    refetchClientList();
                }
            });
        }
    }, []);

    const onChangeClientList = useCallback(
        (value: Selectable<KindergartenClientListing>[]) => {
            setState({ ...state, clients: value });
        },
        [state]
    );
    const onChangeCompleteResponse = useCallback(
        (value: KindergartenClientListing[]) => {
            setState({
                ...state,
                completeResponse: value,
                clients: filterData(state.filters, value),
            });
        },
        [state]
    );
    return (
        <>
            {loadingClientList ? (
                <AppLoader fullHeight={true} />
            ) : (
                <Outlet
                    context={{
                        ...state,
                        isLoading: loadingClientList,
                        isRefetching: isRefetching,
                        completeResponse: clientList,
                        headerFilterOptions:
                            clientsListResponse?.Data.FilterOptions,
                        onChangeClientList: onChangeClientList,
                        onChangeCompleteResponse: onChangeCompleteResponse,
                        refetchData: () => {
                            queryClient.invalidateQueries(
                                getKindergartenClientServiceKey(
                                    "getClientsList"
                                )
                            );
                            refetchClientList();
                        },
                        onFilterChange: (value: KindergartenClientFilters) => {
                            setState((old) => {
                                return {
                                    ...old,
                                    filters: value,
                                    clients: filterData(value),
                                };
                            });
                        },
                        setNavigateUsingCompleteResponse: (val?: boolean) =>
                            setState((old) => ({
                                ...old,
                                navigateUsingCompleteResponse: val,
                            })),
                        onSortChange: (
                            value: TableSort<KindergartenClientSortColumn>
                        ) => {
                            setState((old) => {
                                return {
                                    ...old,
                                    sort: value,
                                    clients:
                                        old.clients && old.clients.length > 0
                                            ? sortList(old.clients, value)
                                            : [],
                                };
                            });
                        },
                    }}
                />
            )}
        </>
    );
};

export default KindergartenClientsContextProvider;
