import { BooleanTickCrossIcon } from "components";
import { AppDeleteButton } from "components/Buttons";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { PermissionAccessMap } from "models";
import { KindergartenClientRegistration } from "models/kindergartenClientRegistration/model";
import { useTranslation } from "react-i18next";
import styles from "./KindergartenClientRegistrationRow.module.scss";
import {
    ClientRegistrationStatus,
    getYearColor,
} from "models/kindergartenClientRegistration";
import classNames from "classnames";
import { AppSelectOld } from "components/AppSelect";
import { isNil } from "lodash-es";
import React, { useMemo } from "react";
import { SelectItem } from "models/general";
import { appTableFilterSelectStyles } from "components/Table";
import { useMutation, useQueryClient } from "react-query";
import { showSweetAlertToast } from "globals/helpers/sweetAlertHelper";
import { useRouting } from "hooks/general/routing";
import {
    getKindergartenClientRegistrationServiceKey,
    KindergartenClientRegistrationService,
} from "services/kindergarten/KindergartenClientRegistrationService";
import { getFixedCssWidths } from "globals/helpers/generalHelper";

export interface KindergartenClientRegistrationRowProps {
    value: KindergartenClientRegistration;
    permMap: PermissionAccessMap;
    onDeleteClick: (id: string) => void;
    onClick: (id: string) => void;
    onChange: (val: KindergartenClientRegistration) => void;
    loading?: boolean;
    className?: string;
}

export const KindergartenClientRegistrationRow: React.FC<KindergartenClientRegistrationRowProps> =
    React.memo(
        ({
            value,
            onDeleteClick,
            permMap,
            onClick,
            onChange,
            className,
            loading = false,
        }) => {
            const { getDateFormatForLocale, getDateTimeFormatForLocale } =
                useLocaleHelpers();
            const { t } = useTranslation();

            const statusOptions = useMemo(() => {
                return [
                    // {
                    //     Text: t("common.pleaseSelect"),
                    //     Value: null,
                    // },
                    ...[
                        ...Object.values(ClientRegistrationStatus).map((x) => {
                            return {
                                Text: t(`common.status.${x.toString()}`),
                                Value: x.toString(),
                            } as SelectItem;
                        }),
                    ],
                ];
            }, [t]);
            const { linkProvider } = useRouting();
            const queryClient = useQueryClient();

            const registrationService =
                new KindergartenClientRegistrationService(
                    linkProvider.kindergarten.clients().api.clientRegistration
                );
            const { mutate: updateStatus, isLoading: updating } = useMutation(
                async (data: {
                    id: string;
                    status: ClientRegistrationStatus;
                }) =>
                    await registrationService.updateStatus(
                        data.id,
                        data.status
                    ),
                {
                    onSuccess: (res, variables) => {
                        if (res && res.Data) {
                            // showSweetAlertToast(
                            //     t("common.success"),
                            //     `${t("kindergartenClient.status")} ${t(
                            //         "common.actions.updatedSuccessfully"
                            //     )}`,
                            //     "success"
                            // );
                            // queryClient.invalidateQueries(
                            //     getKindergartenClientRegistrationServiceKey(
                            //         "list"
                            //     )
                            // );
                            onChange({
                                ...value,
                                Status: variables.status,
                            });
                        }
                    },
                    onError: () => {
                        showSweetAlertToast(
                            t("common.error.error"),
                            t("common.actions.errors.unableToUpdate"),
                            "error"
                        );
                    },
                }
            );

            return (
                <tr
                    onClick={() => {
                        if (permMap.EDIT && value.EncodedId) {
                            onClick(value.EncodedId);
                        }
                    }}
                    className={className}
                    key={value.Id}
                >
                    <td
                        style={{
                            ...getFixedCssWidths(300),
                            paddingLeft: "10px",
                        }}
                    >
                        <span className={styles.textEllipse}>{value.Name}</span>
                    </td>
                    <td style={getFixedCssWidths(170)}>
                        <span className={styles.textEllipse}>
                            {value.Gender
                                ? t(`common.gender.${value.Gender}`)
                                : ""}
                        </span>
                    </td>
                    <td style={getFixedCssWidths(130)}>
                        <span
                            style={{ background: getYearColor(value.Year) }}
                            className={classNames(
                                styles.textEllipse,
                                styles.yearLabel
                            )}
                        >
                            {value.Year}
                        </span>
                    </td>
                    <td style={getFixedCssWidths(200)}>
                        <span className={styles.textEllipse}>
                            {value.Birthday
                                ? value.Birthday.format(
                                      getDateFormatForLocale()
                                  )
                                : ""}
                            {/* t("kindergartenClient.registrations.hasNoBirthday") */}
                        </span>
                    </td>
                    <td style={getFixedCssWidths(200)}>
                        <span className={styles.textEllipse}>
                            {value.CareStart
                                ? value.CareStart.format(
                                      getDateFormatForLocale()
                                  )
                                : ""}
                            {/* t("kindergartenClient.registrations.hasNoCareStart") */}
                        </span>{" "}
                    </td>
                    {/* <td>
                <span className={styles.textEllipse}>
                    {value.AgeGroupFieldName}
                </span>
            </td> */}
                    <td style={getFixedCssWidths(200)}>
                        <span className={styles.textEllipse}>
                            {value.CareScopeFieldName}
                        </span>
                    </td>
                    <td
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        style={{
                            ...getFixedCssWidths(150),
                            ...(permMap.EDIT ? { padding: "2px 10px" } : {}),
                        }}
                    >
                        {permMap.EDIT ? (
                            <AppSelectOld
                                showDropdownIndicator={true}
                                menuPortalTarget={document.body}
                                disabled={updating}
                                options={statusOptions}
                                styles={appTableFilterSelectStyles}
                                getOptionLabel={(opt: SelectItem) => opt.Text}
                                getOptionValue={(opt: SelectItem) => opt.Value}
                                value={
                                    isNil(value.Status)
                                        ? {
                                              Text: t("common.pleaseSelect"),
                                              Value: null,
                                          }
                                        : statusOptions.find(
                                              (x) => x.Value === value.Status
                                          )
                                }
                                onChange={(e: SelectItem) => {
                                    if (
                                        e &&
                                        e.Value &&
                                        e.Value != value.Status
                                    ) {
                                        updateStatus({
                                            id: value.EncodedId as string,
                                            status: e.Value as ClientRegistrationStatus,
                                        });
                                    }
                                }}
                            />
                        ) : (
                            <span className={styles.textEllipse}>
                                {value.Status
                                    ? t(`common.status.${value.Status}`)
                                    : ""}
                            </span>
                        )}
                    </td>
                    <td style={getFixedCssWidths(170)}>
                        <span className={styles.textEllipse}>
                            {value.CountryName
                                ? value.CountryName
                                : value.NationalityRaw}
                        </span>
                    </td>
                    {/* <td>
                <span className={styles.textEllipse}>
                    {value.ProgressStatus
                        ? t(
                              `kindergartenClient.registration.progressStatus.${value.ProgressStatus}`
                          )
                        : ""}
                </span>
            </td> */}
                    <td style={getFixedCssWidths(200)}>
                        <BooleanTickCrossIcon value={value.IsSibling} />
                    </td>
                    <td style={getFixedCssWidths(200)}>
                        <BooleanTickCrossIcon value={value.ArrangedPlace} />
                    </td>
                    <td style={getFixedCssWidths(150)}>
                        <span className={styles.textEllipse}>
                            {value.FromSource
                                ? t(`common.sourceTypes.${value.FromSource}`)
                                : ""}
                        </span>
                    </td>
                    <td style={getFixedCssWidths(200)}>
                        <span className={styles.textEllipse}>
                            {value.CreatedAt.format(
                                getDateTimeFormatForLocale()
                            )}
                        </span>
                    </td>
                    <td style={getFixedCssWidths(70)}>
                        <AppDeleteButton
                            getConfirmation={true}
                            name={value.Name}
                            readonly={!permMap.DELETE || loading}
                            onClick={() =>
                                onDeleteClick(
                                    value.EncodedId ? value.EncodedId : ""
                                )
                            }
                        />
                    </td>
                </tr>
            );
        }
    );
export default KindergartenClientRegistrationRow;
