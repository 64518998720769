import { KindergartenClientContractCopyRequest } from "models";
import { Optional } from "models/general";
import { KindergartenClientRequest } from "models/kindergartenClient/request";
import {
    ClientRegistrationStatus,
    KindergartenClientRegistrationRequest,
} from "models/kindergartenClientRegistration";
import { KindergartenRoutingContext } from "routing/routingContexts";
import {
    getCrudUrlsForSection,
    getUrlForPattern,
} from "routing/routingContexts/helper";

export const KINDERGARTEN_CLIENT_PATH_PREFIX = "clients";
export const kindergartenClientRoutes = (
    //Build url will be true in case of get all complete url (mostly use while navigate)
    //and false is case of route mapping
    buildUrl: boolean = true, // for using in map (we need relative routes in map not complete)
    context: KindergartenRoutingContext = new KindergartenRoutingContext(),
    clientId: string = ":clientId"
) => {
    const clientsBase = KINDERGARTEN_CLIENT_PATH_PREFIX;
    const base = `${context.getBaseUrl()}/${clientsBase}`;

    const apiBase = context.getApiBaseUrl();

    return {
        base: base,
        apiBase: apiBase,
        api: {
            ...((clientApiBase: string) => {
                return {
                    getClientsList: (reqParams?: KindergartenClientRequest) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${clientApiBase}/clients-list`,
                            true,
                            undefined,
                            reqParams
                        ),
                    addUpdateClient: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${clientApiBase}/add-update`,
                            true
                        ),
                    generateAccountingExcel: () =>
                        getUrlForPattern(
                            context,
                            true,
                            `${clientApiBase}/generate-accounting-excel`,
                            true
                        ),
                    getCustomerNumber: (queryData: { lastName: string }) =>
                        getUrlForPattern(
                            context,
                            true,
                            `${clientApiBase}/get-customer-number`,
                            true,
                            undefined,
                            queryData
                        ),
                    withId: (clientEncodeId?: Optional<string> | ":clientId") =>
                        ((idBase: string) => {
                            // here clientId might not be in context so passing it manually
                            return {
                                deleteClient: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${idBase}/delete`,
                                        true
                                    ),
                                getNonSiblings: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${idBase}/get-non-siblings`,
                                        true
                                    ),
                                clientContactAddUpdate: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${idBase}/client-contact-add-update`,
                                        true
                                    ),
                                getClient: () =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${idBase}/get-client`,
                                        true
                                    ),
                                deleteClientContact: (queryData: {
                                    contactId: number;
                                    updateToPrimary: boolean;
                                }) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${idBase}/delete-client-contact`,
                                        true,
                                        undefined,
                                        queryData
                                    ),
                                addSibling: (siblingClientId: number) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${idBase}/add-sibling/${siblingClientId}`,
                                        true
                                    ),
                                deleteSibling: (id: number) =>
                                    getUrlForPattern(
                                        context,
                                        true,
                                        `${idBase}/delete-sibling/${id}`,
                                        true
                                    ),
                                clientContracts: ((contractBase: string) => {
                                    return {
                                        list: (year: number) =>
                                            getUrlForPattern(
                                                context,
                                                true,
                                                `${contractBase}/list`,
                                                true,
                                                undefined,
                                                { year: year }
                                            ),
                                        update: (id: number) =>
                                            getUrlForPattern(
                                                context,
                                                true,
                                                `${contractBase}/update/:id`,
                                                true,
                                                { id: id.toString() }
                                            ),
                                        copy: (id: string, tillEnd: boolean) =>
                                            getUrlForPattern(
                                                context,
                                                true,
                                                `${contractBase}/:id/${
                                                    tillEnd
                                                        ? "copy-to-end"
                                                        : "copy"
                                                }`,
                                                true,
                                                { id: id }
                                            ),
                                    };
                                })(`${idBase}/contracts`),
                            };
                        })(
                            `${clientApiBase}/${
                                clientEncodeId ? clientEncodeId : clientId
                            }`
                        ),
                    clientRegistration: ((contractBase: string) => {
                        return {
                            list: (
                                reqParams?: KindergartenClientRegistrationRequest
                            ) =>
                                getUrlForPattern(
                                    context,
                                    true,
                                    `${contractBase}/list`,
                                    true,
                                    undefined,
                                    reqParams
                                ),
                            createUpdate: () =>
                                getUrlForPattern(
                                    context,
                                    true,
                                    `${contractBase}/add-update`,
                                    true
                                ),
                            updateStatus: (
                                id: string,
                                status: ClientRegistrationStatus
                            ) =>
                                getUrlForPattern(
                                    context,
                                    true,
                                    `${contractBase}/:id/update-status`,
                                    true,
                                    { id: id.toString() },
                                    { status }
                                ),
                            parentCreateUpdate: (id: string) =>
                                getUrlForPattern(
                                    context,
                                    true,
                                    `${contractBase}/:id/parent-add-update`,
                                    true,
                                    { id: id.toString() }
                                ),
                            get: (id: string) =>
                                getUrlForPattern(
                                    context,
                                    true,
                                    `${contractBase}/get/${id}`,
                                    true
                                ),
                            delete: (id: string) =>
                                getUrlForPattern(
                                    context,
                                    true,
                                    `${contractBase}/delete/${id}`,
                                    true
                                ),
                            deleteContact: (
                                id: string,
                                registrationId: string
                            ) =>
                                getUrlForPattern(
                                    context,
                                    true,
                                    `${contractBase}/delete-contact/${registrationId}/${id}`,
                                    true
                                ),
                            deleteFile: (id: number) =>
                                getUrlForPattern(
                                    context,
                                    true,
                                    `${contractBase}/delete-file/${id}`,
                                    true
                                ),
                        };
                    })(`client-registration`),
                };
            })(`client`),
        },
        screens: {
            registrations: ((sectionBase: string) => {
                return getCrudUrlsForSection(
                    sectionBase,
                    context,
                    buildUrl,
                    undefined,
                    undefined,
                    undefined,
                    true
                );
            })(`${clientsBase}/registrations`),
            client: {
                create: () => {
                    return getUrlForPattern(
                        context,
                        buildUrl,
                        `${clientsBase}/create`
                    );
                },
                list: () => {
                    return getUrlForPattern(
                        context,
                        buildUrl,
                        `${clientsBase}`
                    );
                },
                withId: (clientEncodeId?: Optional<string> | ":clientId") =>
                    ((clientEditBase: string) => {
                        const subPathBase = buildUrl
                            ? `${clientsBase}/${clientEditBase}/`
                            : ``;

                        return {
                            base: () => {
                                // for edit-layout
                                return getUrlForPattern(
                                    context,
                                    buildUrl,
                                    buildUrl
                                        ? `${clientsBase}/${clientEditBase}`
                                        : `${clientEditBase}`
                                );
                            },
                            edit: () => {
                                return getUrlForPattern(
                                    context,
                                    buildUrl,
                                    `${subPathBase}edit`
                                );
                            },
                            contracts: () => {
                                return getUrlForPattern(
                                    context,
                                    buildUrl,
                                    `${subPathBase}contracts`
                                );
                            },
                            app: () => {
                                return getUrlForPattern(
                                    context,
                                    buildUrl,
                                    `${subPathBase}app`
                                );
                            },
                            medicalData: () => {
                                return getUrlForPattern(
                                    context,
                                    buildUrl,
                                    `${subPathBase}medical-data`
                                );
                            },
                            careGroups: () => {
                                return getUrlForPattern(
                                    context,
                                    buildUrl,
                                    `${subPathBase}care-groups`
                                );
                            },
                            accessControl: () => {
                                return getUrlForPattern(
                                    context,
                                    buildUrl,
                                    `${subPathBase}access-control`
                                );
                            },
                            documents: ((documentBase: string) => {
                                return {
                                    list: () =>
                                        getUrlForPattern(
                                            context,
                                            buildUrl,
                                            documentBase
                                        ),
                                    fillEditableSections: (
                                        docId: string = ":docId"
                                    ) =>
                                        getUrlForPattern(
                                            context,
                                            buildUrl,
                                            `${documentBase}/${docId}/fill-editable-sections`
                                        ),
                                };
                            })(`${subPathBase}documents`),
                            diseases: ((sectionBase: string) => {
                                return getCrudUrlsForSection(
                                    sectionBase,
                                    context,
                                    buildUrl
                                );
                            })(`${subPathBase}diseases`),
                            vacations: ((sectionBase: string) => {
                                return getCrudUrlsForSection(
                                    sectionBase,
                                    context,
                                    buildUrl
                                );
                            })(`${subPathBase}vacations`),
                            family: ((sectionBase: string) => {
                                return getCrudUrlsForSection(
                                    sectionBase,
                                    context,
                                    buildUrl
                                );
                            })(`${subPathBase}family`),
                            bankData: ((sectionBase: string) => {
                                return getCrudUrlsForSection(
                                    sectionBase,
                                    context,
                                    buildUrl
                                );
                            })(`${subPathBase}bank-data`),
                        };
                    })(`${clientEncodeId ? clientEncodeId : clientId}`),
            },
        },
    };
};

export default kindergartenClientRoutes;
