import { AppSelectOld, AppSelectOldProps } from "components/AppSelect";
import useLocaleHelpers from "hooks/general/localeHelpers";
import { isNil } from "lodash-es";
import { SelectItem } from "models/general";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface YearsFilterDropdownProps {
    value?: number;
    readonly?: boolean;
    options?: SelectItem[];
    usePleaseSelect?: boolean;
    onChange: (val?: number) => void;
}

export const YearsFilterDropdown: React.FC<
    YearsFilterDropdownProps & Pick<AppSelectOldProps, "styles">
> = ({
    value,
    readonly,
    styles,
    options = [] as SelectItem[],
    usePleaseSelect = false,
    onChange: onValueChange,
}) => {
    const { t } = useTranslation();
    const { appLocale } = useLocaleHelpers();

    const { yearOptions, defaultOption } = useMemo(() => {
        const defaultOption: SelectItem = {
            Text: usePleaseSelect ? t("common.pleaseSelect") : t("common.all"),
            Value: null,
        };
        return { yearOptions: [defaultOption, ...options], defaultOption };
    }, [options, appLocale]);

    return (
        <AppSelectOld
            showDropdownIndicator={isNil(value)}
            showCustomClearIndicator={true}
            menuPortalTarget={document.body}
            styles={styles}
            options={yearOptions}
            isDisabled={readonly}
            getOptionValue={(opt: SelectItem) => opt.Value}
            value={
                value && yearOptions.find((x) => x.Value == value)
                    ? yearOptions.find((x) => x.Value == value)
                    : defaultOption
            }
            getOptionLabel={(opt: SelectItem) => opt.Text}
            onChange={(e: SelectItem) =>
                e && onValueChange(e.Value ? (e.Value as number) : undefined)
            }
        />
    );
};
