import React from "react";
import { MenuListComponentProps } from "react-select/src/components/Menu";
import { FixedSizeList, FixedSizeListProps } from "react-window";
import { SelectComponentProps } from "./types";
import { OptionTypeBase } from "react-select";

const TypedFixedSizeList: any = FixedSizeList;

export function MenuList<OptionType extends OptionTypeBase>(
    props: SelectComponentProps<MenuListComponentProps<OptionType, false>>
) {
    const other = props.selectProps.controlProps.fullWidth
        ? {
              width: "100%",
          }
        : {};

    const fixedSizeListProps: FixedSizeListProps = {
        ...other,
        ...props.selectProps.fixedSizeListProps,
    };
    const childs: any = props.children;
    const count = React.Children.count(childs);
    return (
        <TypedFixedSizeList
            {...fixedSizeListProps}
            itemSize={40}
            height={40 * Math.min(5, count)}
            itemCount={count}
            overscanCount={5}
        >
            {(renderProps: any) => (
                <div style={renderProps.style}>{childs[renderProps.index]}</div>
            )}
        </TypedFixedSizeList>
    );
}
