import React, { CSSProperties } from "react";
import styles from "./MonthYearSplittedDropdown.module.scss";
import classNames from "classnames";
import { SelectItem } from "models/general";
import { MonthsFilterDropdown } from "components/FormFields/MonthsFilterDropdown";
import { appTableFilterSelectStyles } from "components/Table";
import { getUniqueList } from "globals/helpers/generalHelper";
import { YearsFilterDropdown } from "components/FormFields/YearsFilterDropdown";
import { defaultTo } from "lodash-es";
import { toNumber } from "globals/helpers/localizationHelpers";

interface MonthYear {
    month?: number;
    year?: number;
}
export interface MonthYearSplittedDropdownsProps {
    onChange: (val: MonthYear) => void;
    value: MonthYear;
    className?: string;
    style?: CSSProperties;
    readonly?: boolean;
    useNumericMonths?: boolean;
    yearOptions?: SelectItem[];
}
export const MonthYearSplittedDropdowns: React.FC<
    MonthYearSplittedDropdownsProps
> = ({
    value,
    onChange,
    className,
    readonly,
    style,
    yearOptions = [],
    useNumericMonths = true,
}) => {
    return (
        <div
            className={classNames(styles.splittedDate, className)}
            style={style}
        >
            <MonthsFilterDropdown
                readonly={readonly}
                useShortNames={true}
                useNumbers={useNumericMonths}
                value={value.month}
                styles={appTableFilterSelectStyles}
                onChange={(newVal?: number) => {
                    onChange({
                        ...value,
                        month: newVal,
                    });
                }}
            />
            <YearsFilterDropdown
                styles={appTableFilterSelectStyles}
                options={getUniqueList(yearOptions, "Value")}
                value={value.year}
                onChange={(val?: number) => {
                    const newY = toNumber(val);
                    onChange({
                        ...value,
                        year: newY > 0 ? newY : undefined,
                    });
                }}
            />
        </div>
    );
};

export default MonthYearSplittedDropdowns;
