import {
    FULL_DATE_RANGE_FORMAT,
    TWO_PRECISION_NUMERIC,
} from "globals/constants";
import { getBrowserLanguage } from "globals/helpers/localizationHelpers";
import { useAppContext } from "hoc/providers/AppContextProvider";
import { useSessionBusiness } from "hooks/general/appContextHelpers";
import { defaultTo, isNil } from "lodash-es";
import { WorkingDaysNumber } from "models";
import {
    getMomentDateFormat,
    getMomentDateTimeFormat,
    getMomentDayMonthShortFormat,
    getMomentTimeFormat,
} from "models/datepicker";
import { AppCurrencyCode, AppLocale } from "models/general/enum";
import { Optional } from "models/general/models";
import moment, { Moment } from "moment-timezone";
import numeral from "numeral";
import { useMemo } from "react";

export const useLocaleHelpers = () => {
    const { locale } = useAppContext();
    const { sessionBusiness } = useSessionBusiness();
    const appLocale = defaultTo(locale, getBrowserLanguage());
    const toReturn = useMemo(() => {
        let currencyCode = AppCurrencyCode.EUR;
        let tz = "utc";
        if (sessionBusiness) {
            currencyCode =
                sessionBusiness.CurrencyCode == AppCurrencyCode.USD
                    ? sessionBusiness.CurrencyCode
                    : AppCurrencyCode.EUR;
            tz = sessionBusiness.Timezone;
        }
        const formatNumber = (
            value: Optional<number>,
            format: string = TWO_PRECISION_NUMERIC,
            formatNull: boolean = true
        ) => {
            return !isNil(value) || formatNull
                ? numeral(defaultTo(value, 0)).format(format)
                : "";
        };

        return {
            appLocale: appLocale,
            currencyCode: currencyCode,
            currencySymbol: currencyCode == AppCurrencyCode.USD ? "$" : "€",
            formatDate: (
                value: Moment,
                format: string = getMomentDateFormat(appLocale)
            ): string => {
                return value
                    ? value.locale(moment.locale()).format(format)
                    : moment().format(format);
            },
            getDateFormatForLocale: () => {
                return getMomentDateFormat(appLocale);
            },
            getDayMonthShortFormatForLocale: () => {
                return getMomentDayMonthShortFormat(appLocale);
            },
            getTimeFormatForLocale: () => {
                return getMomentTimeFormat(appLocale);
            },
            getDateTimeFormatForLocale: () => {
                return getMomentDateTimeFormat(appLocale);
            },
            getDateTimeDisplayFormatForLocale: () => {
                return `${FULL_DATE_RANGE_FORMAT} ${getMomentTimeFormat(
                    appLocale
                )}`;
            },
            getTimePickerFormatForLocale: () => {
                return appLocale === AppLocale.GERMAN ? "24h" : "12h";
            },
            convertToSessionTimeZone(date: Moment) {
                return moment(date).tz(tz);
            },
            formatNumber: formatNumber,
            getFormattedCurrency: (
                value: number,
                format: string = TWO_PRECISION_NUMERIC
            ) => {
                const val = formatNumber(value, format);
                return currencyCode === AppCurrencyCode.USD
                    ? `$ ${val}`
                    : `${val} €`;
            },
        };
    }, [appLocale, moment, numeral]);

    return {
        ...toReturn,
        isToday: (momentDate: Moment) => {
            return (
                momentDate.format(toReturn.getDateFormatForLocale()) ===
                moment().format(toReturn.getDateFormatForLocale())
            );
        },
        isYesterday: (momentDate: Moment) => {
            return (
                momentDate.format(toReturn.getDateFormatForLocale()) ===
                moment()
                    .add(-1, "days")
                    .format(toReturn.getDateFormatForLocale())
            );
        },
        isWeekend: (value: Date): boolean => {
            const day = value.getDay(); // sunday=0, monday=1
            let mod = day % 7;
            if (mod == 0) {
                mod = 7;
            }
            return (
                mod == WorkingDaysNumber.Saturday ||
                mod == WorkingDaysNumber.Sunday
            );
        },
    };
};

export default useLocaleHelpers;
